<template>
    <v-container class="mt-2" fluid tag="section">

        <v-row>
            <v-col cols="12" sm="12">
                <v-row justify="center">
                    <v-col cols="12" sm="3">
                        <v-select color="blue-grey lighten-4" item-color="blue-grey lighten-4" v-model="anio" :menu-props="{ top: true, offsetY: false }" placeholder="Seleccione el año" 
                            :items="anios" item-value="id" item-text="anio" label="AÑO" @change="selectAnio('anio')">
                        </v-select>
                    </v-col>
                    
                    <v-col cols="12" sm="3">
                        <v-select color="blue-grey lighten-4" item-color="blue-grey lighten-4" v-model="tipoNomina" :menu-props="{ top: true, offsetY: false }" placeholder="Seleccione tipo de nómina" 
                            :items="tiposNomina" item-text="name" item-value="tipo" label="TIPO NÓMINA" @change="selectTipoNomina('tipo_nomina')">
                        </v-select>
                    </v-col>
                    <template v-if="tipoNomina">
                        <v-col v-if="tipoNomina != 2" cols="12" sm="3">
                            <v-select color="blue-grey lighten-4" item-color="blue-grey lighten-4" v-model="quincena" :menu-props="{ top: true, offsetY: false }" placeholder="Seleccione la quincena" 
                                :items="quincenas" label="QUINCENA" item-value="id" item-text="quincena" @change="selectPeriodoOMes('periodo_o_mes')">
                            </v-select>
                        </v-col>
                        <v-col v-else cols="12" sm="3">
                            <v-select color="blue-grey lighten-4" item-color="blue-grey lighten-4" v-model="quincena" :menu-props="{ top: true, offsetY: false }" placeholder="Seleccione la quincena" 
                                :items="meses" label="MES" item-value="mes" item-text="nombre" @change="selectPeriodoOMes('periodo_o_mes')">
                            </v-select>
                        </v-col>
                    </template>
                    
                    

                </v-row>
            </v-col>
            <v-col cols="12" sm="12">
                <v-server-table ref="comprobantes" :url="url" :columns="columns" :options="options">
                    <template v-slot:ver_archivo="{row}">
                        <div class="text-center">
                            <v-btn class="mx-2" fab dark small color="red" @click="verArchivo(row)">
                                <v-icon dark>
                                    mdi-clipboard-file-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        
                    </template>

                </v-server-table>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="show"
            :timeout="5000"
            :value="true"
            absolute
            right
            top
            :color="color"
        >{{ text }}</v-snackbar>
    </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { config } from '../../../config';
export default {

    created(){

        for(let i = 1; i <= 24;i++){
            this.quincenas.push({
                id:i,
                quincena:i
            });
        }

        let date = new Date();
        for(let i = 2015; i <= date.getFullYear();i++){
            this.anios.push({
                id:i,
                anio:i
            });
        }

        this.anios.unshift({id:0,anio:'TODOS'});
        this.quincenas.unshift({id:0,quincena:'TODOS'});
        
        this.url = config.baseURL+`/historial-comprobantes/get-comprobantes?rfc=${this.user.rfc}&filters=${JSON.stringify(this.filters)}`;

    },
    data(){
        return ({
            url:'',
            color:'green',
            text:null,
            show:false,
            tipoNomina:null,
            anio:null,
            quincena:null,
            anios:[],
            quincenas:[],
            recors:[],
            columns: ["anio","tipo_nomina","mes","quincena",'ver_archivo'],
            options: {
                headings: {
                    anio: 'AÑO',
                    tipo_nomina:'TIPO DE NÓMINA',
                    mes:'MES',
                    quincena: "QUINCENA",
                    ver_archivo: "ARCHIVO",
                    
                },
                sortable: [],
                texts: {
                    count:"",
                    filter:"Buscar:",
                    filterPlaceholder: 'Buscar...',
                    limit:"Resultados:",
                    recordsDisplay: 'Resultados',
                    page:"Página:",
                    noResults:"No hay resultados",
                    filterBy: 'Buscar...',
                    loading:'Cargando...',
                    defaultOption: 'Seleccionar {column}'
                },
                filterable: false,
                filterByColumn: false,
                resizableColumns: false,
                sendEmptyFilters :false,
                childRowTogglerFirst: true,
                showChildRowToggler: true,
                perPage: 5,
                perPageValues: [5, 10, 15, 20],
                responseAdapter: function(resp) {
                    // console.log(resp);
                    var data = this.getResponseData(resp);
                    try{
                        return {
                            data: data.data,
                            count: data.count
                        };
                    }catch(error){
                        console.log(error);
                    }
                },
                templates:{
                    tipo_nomina(h,row,index){
                        switch(parseInt(row.tipo_nomina)){
                            case 1:
                                return 'COMPENSACIÓN';
                            case 2:
                                return 'EXTRAORDINARIA';
                            case 3:
                                return 'ORDINARIA';
                        }

                    },
                    mes(h,row,index){
                        if(row.mes != null) return row.mes;
                        return "NA";
                    },
                    quincena(h,row,index){
                        if(row.quincena != null) return row.quincena;
                        return "NA";
                    }
                }
            },
            tiposNomina: [
                {
                    tipo:0,
                    name:'TODOS'
                },
                {
                    tipo: 1,
                    name:"COMPENSACIÓN",
                },
                {
                    tipo: 3,
                    name:"ORDINARIA",
                },
                {
                    tipo: 2,
                    name:"EXTRAORDINARIA",
                }
            ],
            meses:[
                {
                    mes:0,
                    nombre:'TODOS'
                },
                {
                    mes:1,
                    nombre:'ENERO'
                },
                {
                    mes:2,
                    nombre:'FEBRERO'
                },
                {
                    mes:3,
                    nombre:'MARZO'
                },
                {
                    mes:4,
                    nombre:'ABRIL'
                },
                {
                    mes:5,
                    nombre:'MAYO'
                },
                {
                    mes:6,
                    nombre:'JUNIO'
                },
                {
                    mes:7,
                    nombre:'JULIO'
                },
                {
                    mes:8,
                    nombre:'AGOSTO'
                },
                {
                    mes:9,
                    nombre:'SEPTIEMBRE'
                },
                {
                    mes:10,
                    nombre:'OCTUBRE'
                },
                {
                    mes:11,
                    nombre:'NOVIEMBRE'
                },
                {
                    mes:12,
                    nombre:'DICIEMBRE'
                }
            ],
            filters:{
                anio:0,
                tipo_nomina:0,
                periodo_o_mes:0

            },
        });
    },
    computed:{
        ...mapState(["user","token"])
    },
    mounted(){
    },
    methods:{

        notify(text,color = "green"){
            this.text = text;
            this.color = color;
            this.show = true;
        },
        selectAnio(filter){
            this.filters[filter] = this.anio;
            this.url = config.baseURL+`/historial-comprobantes/get-comprobantes?rfc=${this.user.rfc}&filters=${JSON.stringify(this.filters)}`;
        },
        selectTipoNomina(filter){
            this.filters[filter] = this.tipoNomina;
            this.url = config.baseURL+`/historial-comprobantes/get-comprobantes?rfc=${this.user.rfc}&filters=${JSON.stringify(this.filters)}`;
        },
        selectPeriodoOMes(filter){
            this.filters[filter] = this.quincena;
            this.url = config.baseURL+`/historial-comprobantes/get-comprobantes?rfc=${this.user.rfc}&filters=${JSON.stringify(this.filters)}`;
        },
        verArchivo(archivo){
            window.open(config.baseURL+`historial-comprobantes/archivo/${archivo.id}?token=${this.token}`);
        }
    }
}
</script>
<style >
.VueTables__limit-field  select {
    width: unset;
}

.VueTables__limit-field {
    margin-bottom: .75rem;
}

.pagination{
    justify-content: center;
}

</style>